.number-circle {
    border-radius: 50%;
    text-align: center;

    &--38 {
        width: 38px;
        line-height: 38px;
        font-size: 20px;
    }

    &--olive-active {
        background: #2e7555;
        color: #fff;
    }

    &--blue-active {
        background: #0062cc;
        color: #fff;
    }
}
