.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}


// bounce-11 - scale(1.1)
.bounce-11-enter-active {
    animation: bounce-11-in .5s;
}

.bounce-11-leave-active {
    animation: bounce-11-in .5s reverse;
}

@keyframes bounce-11-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.flip-list-03-move {
    transition: transform 0.3s;
}
