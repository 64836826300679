.vs__dropdown-toggle {
    padding: 2px 0 3px;
}

.vs__search, .vs__search:focus {
    margin: 4px 0 2px;
}

.vs__selected {
    padding: 0 .5em 2px;
    margin: 2px 2px 2px;
}

.v-select:not(.vs--single) {
    .vs__selected {
        background-color: #007bff;
        border: 1px solid #006fe6;
        color: #fff;
    }
}

.vs__deselect {
    fill: rgba(255, 255, 255, 0.75);
    margin-left: 6px;
}

.vs__dropdown-option--selected {
    background: #e9ecef;

    &:hover {
        background-color: #5897fb;
    }
}

.vs__clear {
    margin-top: -5px;
}

.vs__dropdown-option--highlight {
    .text-muted {
        color: #fff !important;
    }
}
