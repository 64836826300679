.loading-form-field {
    .input-group-text {
        border-right: none;
    }

    .form-control {
        border-left: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.card-header {
    .search-box {
        max-width: 200px;
        margin-left: -0.42rem;
    }
}

.card-settings-block {
    .custom-checkbox {
        label {
            font-weight: normal;
        }
    }
}

.date-time-picker {
    .field-input {
        height: 36px;
        min-height: 36px;
        border: none !important;
    }
}

.tendering-bell {
    color: #c7c7c7;

    &--active {
        color: #007bff;
    }
}

#toast-container > div {
    opacity: 1;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-left: 10px;
}

// компактный вид .list-group
.list-group-condensed {
    &.list-group {
        .list-group-item {
            padding: 0.25rem 0;
        }
    }
}

// кастомный чекбокс без input, тут `:checked` заменено на класс `.checked`

.checked.custom-control-label::before {
    color: #ffffff;
    border-color: #007bff;
    background-color: #007bff;
    box-shadow: none;
}

.checked.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
