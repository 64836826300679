// Fonts
@import 'public/AdminLTE/plugins/fontawesome-free/css/all.min';

// Variables
@import 'variables';

// Template
@import 'public/AdminLTE/build/scss/app.scss';

// Font
@import '../fonts/fontello/css/tendera-online.css';

@import 'logo';
@import 'transition';
@import 'typo';

@import 'aggrid';
@import '../../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css';
@import '../../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

@import '../../node_modules/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

@import '../../node_modules/@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

@import '../../node_modules/vue-select/dist/vue-select.css';
@import 'vue-select';
@import '../../public/AdminLTE/plugins/toastr/toastr.min.css';
@import 'misc';

