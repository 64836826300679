.ag-theme-alpine {
    &.ag-theme-alpine--custom {
        .float-filter-select {
            padding: 1px 2px 1px 6px;
            height: 24px;
            font-size: 13px;
        }

        .ag-column-drop-horizontal, .ag-menu, .ag-menu-header, .ag-header {
            background-color: #ffffff;
        }

        .ag-row-odd {
            background-color: #fcfcfc;
        }

        .ag-row-hover {
            background-color: #c4e2ff;
        }

        // удаленная строка
        .ag-row {
            &.ag-row-deleted {
                background: #ffe9e9;
            }
        }

        .ag-header-cell, .ag-header-group-cell {
            padding-left: 14px;
            padding-right: 14px;
        }

        .ag-cell {
            border: 1px solid transparent;
            padding-left: 13px;
            padding-right: 13px;
        }

        .ag-ltr .ag-floating-filter-button {
            margin-left: 10px;
            margin-right: -10px;
        }

        .ag-root-wrapper {
            border: none;
        }
    }
}

// колонка ID
.aggrid-cell-id {
    overflow: visible !important;

    .dropdown-item {
        font-size: 0.85rem;
    }
}

// колонка действий
.aggrid-cell-actions {
    text-align: center;
}

// кнопка просмотра / редактирования / удаления
.aggrid-btn {
    width: 24px;
}
